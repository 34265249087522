<template>
    <div>
        <v-simple-table class="mt-3 appic-table-light specification-table" dense>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th class="grey lighten-3 py-0 pr-2 pl-2 text-left width-1-pct text-start">{{ $t('message.lineNo') }}</th>
                        <th class="grey lighten-3 py-0 pr-0 pl-2 text-left text-start">{{ $t('message.bundle') }}</th>
                        <th class="grey lighten-3 py-0 pr-0 pl-2 text-left text-start">{{ $t('message.certification') }}</th>
                        <th class="grey lighten-3 py-0 pr-0 pl-2 text-left text-start">{{ $t('message.description') }}</th>
                        <th class="grey lighten-3 py-0 pr-0 pl-2 text-left text-start">{{ $t('message.size') }}</th>
                        <th class="grey lighten-3 py-0 pr-0 pl-2 text-left text-start">{{ $t('message.quantity') }}</th>
                        <th class="grey lighten-3 py-0 pr-0 pl-2 text-left text-start">{{ $t('message.costPrice') }}</th>
                        <th class="grey lighten-3 py-0 pr-0 pl-2 text-left text-start">{{ $t('message.salesPrice') }}</th>
                        <th class="grey lighten-3 py-0 pr-0 pl-2 text-left text-start">{{ $t('message.amount') }}</th>
                        <th class="grey lighten-3 py-0 pr-2 pl-2 text-left width-1-pct text-start">{{ $t('message.actions') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><div class="data-holder"></div></td>
                        <td><div class="data-holder"></div></td>
                        <td><div class="data-holder"></div></td>
                        <td class="pb-3">
                            <div class="d-flex flex-column">
                                <div class="data-holder"></div>
                                <div class="data-holder"></div>
                                <div class="data-holder"></div>
                                <div class="data-holder"></div>
                            </div>
                        </td>
                        <td>
                            <div class="d-flex flex-column">
                                <div class="data-holder"></div>
                                <div class="data-holder"></div>
                                <div class="data-holder"></div>
                            </div>
                        </td>
                        <td>
                            <div class="d-flex flex-column">
                                <div class="data-holder"></div>
                                <div class="data-holder"></div>
                                <div class="data-holder"></div>
                            </div>
                        </td>
                        <td>
                            <div class="d-flex flex-column">
                                <div class="data-holder"></div>
                                <div class="data-holder"></div>
                                <div class="data-holder"></div>
                            </div>
                        </td>
                        <td><div class="data-holder"></div></td>
                        <td><div class="data-holder"></div></td>
                        <td><div class="data-holder"></div></td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <v-row no-gutters class="justify-end">
            <v-col cols="4"></v-col>
            <v-col cols="8">
                <v-row no-gutters class="justify-end">
                    <v-col cols="2" class="d-flex flex-column text-end px-3">
                        <span class="font-weight-bold font-sm pr-1">{{ $t('message.totalPieces') }}</span>
                        <div class="data-holder"></div>
                    </v-col>
                    <v-col cols="2" class="d-flex flex-column text-end px-3">
                        <span class="font-weight-bold font-sm pr-1">{{ $t('message.totalVolAndQty') }}</span>
                        <div class="data-holder"></div>
                    </v-col>
                    <v-col cols="2" class="d-flex flex-column text-end px-3">
                        <span class="font-weight-bold font-sm pr-1">{{ $t('message.currency') }}</span>
                        <div class="data-holder"></div>
                    </v-col>
                    <v-col cols="2" class="d-flex flex-column text-end px-3">
                        <span class="font-weight-bold font-sm pr-1">{{ $t('message.exRate') }}</span>
                        <div class="data-holder"></div>
                    </v-col>
                    <v-col cols="2" class="d-flex flex-column text-end px-3">
                        <span class="font-weight-bold font-sm pr-1">{{ $t('message.totalAmount') }}</span>
                        <div class="data-holder"></div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: "WarehouseInvoiceItemList"
}
</script>

<style scoped>
@keyframes flickerAnimation {
    0%   { opacity:1; }
    50%  { opacity:0.4; }
    100% { opacity:1; }
}
@-o-keyframes flickerAnimation{
    0%   { opacity:1; }
    50%  { opacity:0.4; }
    100% { opacity:1; }
}
@-moz-keyframes flickerAnimation{
    0%   { opacity:1; }
    50%  { opacity:0.4; }
    100% { opacity:1; }
}
@-webkit-keyframes flickerAnimation{
    0%   { opacity:1; }
    50%  { opacity:0.4; }
    100% { opacity:1; }
}
.data-holder {
    -webkit-animation: flickerAnimation 3s infinite;
    -moz-animation: flickerAnimation 3s infinite;
    -o-animation: flickerAnimation 3s infinite;
    animation: flickerAnimation 3s infinite;
    height: 24px !important;
    border-radius: 12px;
    background-color: lightgray;
    margin-top: 5px;
}
table td.text-start {
    padding-left: 3px !important;
    padding-right: 0px !important;
}
.v-data-table::v-deep tbody td {
    vertical-align: top !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    height: 40px !important;
}
.v-data-table::v-deep thead th {
    font-size: 0.875rem !important;
    font-weight: bold !important;
    color: black !important;
    padding-bottom: 0px !important;
    padding-top: 0px !important;
    height: 21px !important;
}
</style>