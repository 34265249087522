<template>
    <div>
        <LoadingItems v-if="!itemsLoaded"/>
        <v-lazy>
        <v-data-table
            :disable-pagination="disablePagination"
            :items-per-page="itemsPerPage"
            :hide-default-footer="disablePagination"
            :headers="headers"
            :height="LocalSalesInvoiceItems.length < 2 ? '170px' : null"
            :items="LocalSalesInvoiceItems"
            calculate-widths
            class="mt-3 appic-table-light specification-table"
            dense
            disable-sort
            hide-default-header
            item-key="index"
            single-select
            v-if="itemsLoaded"
        >
            <template v-slot:header="{ props: { headers } }">
                <tr>
                    <th class="grey lighten-3 pa-1 text-center width-1-pct">{{ headers[0].text }}</th>
                    <th class="grey lighten-3 pa-1 text-left">{{ headers[1].text }}</th>
                    <th class="grey lighten-3 pa-1 text-left">{{ headers[2].text }}</th>
                    <th class="grey lighten-3 pa-1 text-left">{{ headers[3].text }}</th>
                    <th class="grey lighten-3 pa-1 text-left width-18-pct">{{ headers[4].text }}</th>
                    <th class="grey lighten-3 pa-1 text-left">{{ headers[5].text }}</th>
                    <th class="grey lighten-3 pa-1 text-left">{{ headers[6].text }}</th>
                    <th class="grey lighten-3 pa-1 text-left">{{ headers[7].text }}</th>
                    <th class="grey lighten-3 pa-1 text-left">{{ headers[8].text }}</th>
                    <th class="grey lighten-3 pa-1 text-center width-1-pct">{{ headers[9].text }}</th>
                </tr>
            </template>
            <template v-slot:item.lineNumber="{ item }">
                <div class="text-center mt-2 font-16px">{{ item.lineNumber }}</div>
            </template>
            <template v-slot:item.Stock.bundleno="{ item }">
                <div class="font-weight-bold mt-2 text-no-wrap font-16px">{{ item.Stock.bundleno }}</div>
            </template>
            <template v-slot:item.Stock.fsc="{ item }">
                <v-select
                    :items="allCertifications"
                    :value="item.Stock.fsc"
                    autocomplete="off"
                    class="custom pa-1 bg-lightgrey"
                    clearable
                    dense
                    hide-details="auto"
                    item-text="Certification.title"
                    item-value="Certification.title"
                    v-if="item.type == 'default'"
                    @change="updateLocalSalesInvoiceItemRowHandle( item.index, 'Stock.fsc',$event)"
                ></v-select>
                <v-text-field
                    :value="item.Stock.fsc_description"
                    autocomplete="off"
                    class="custom force-text-left pa-1 bg-lightgrey"
                    clearable
                    dense
                    hide-details="auto"
                    v-if="item.type == 'default' && false"
                    @change="updateLocalSalesInvoiceItemRowHandle( item.index, 'Stock.fsc_description',$event)"
                ></v-text-field>
                <template v-if="updateMode == false && item.Errors && item.Errors.length > 0">
                    <v-alert class="mt-2 font-16px" dense text type="warning" icon="warning">
                        <div class="d-flex flex-column">
                            <template v-for="error in item.Errors">
                                <span class="black--text">{{ error }} </span>
                            </template>
                            <span class="black--text">{{ $t('message.errors.soCannotConvert') }}</span>
                        </div>
                    </v-alert>
                </template>
            </template>
            <template v-slot:item.Stock.salesdescription_id="{ item }">
                <div class="d-flex flex-row align-center" v-if="item.type == 'default'">
                    <div style="min-width: 50px !important;" class="font-16px">{{ $t('message.product') + ":" }}</div>
                    <v-autocomplete
                        :items=allProductGroups
                        :placeholder="$t('message.startTypingToSearch')"
                        :value="item.Stock.productgroup_id"
                        autocomplete="off"
                        class="custom pa-1 bg-lightgrey"
                        clearable
                        dense
                        hide-details="auto"
                        item-value="ProductGroup.id"
                        item-text="ProductGroup.title"
                        v-if="item.type == 'default'"
                        @input="updateLocalSalesInvoiceItemRowHandle( item.index, 'Stock.productgroup_id',$event)"
                    ></v-autocomplete>
                </div>
                <div class="d-flex flex-row align-center" v-if="item.type == 'default'">
                    <div style="min-width: 50px !important;" class="font-16px">{{ $t('message.species') + ":" }}</div>
                    <v-autocomplete
                        :items=allSpecies
                        :placeholder="$t('message.startTypingToSearch')"
                        :value="item.Stock.species_id"
                        autocomplete="off"
                        class="custom pa-1 bg-lightgrey"
                        clearable
                        dense
                        hide-details="auto"
                        item-value="Species.id"
                        item-text="Species.title"
                        return-object
                        v-if="item.type == 'default'"
                        @input="updateLocalSalesInvoiceItemRowHandle( item.index, 'Stock.species_id',$event)"
                    ></v-autocomplete>
                </div>
                <div class="d-flex flex-row align-center" v-if="item.type == 'default'">
                    <div style="min-width: 50px !important;" class="font-16px">{{ $t('message.spec') + ":" }}</div>
                    <v-text-field
                        :value="item.Stock.salesdescription"
                        autocomplete="off"
                        class="custom force-text-left pa-1 bg-lightgrey"
                        clearable
                        dense
                        hide-details="auto"
                        v-if="item.type == 'default'"
                        @change="updateLocalSalesInvoiceItemRowHandle( item.index, 'Stock.salesdescription',$event)"
                    ></v-text-field>
                </div>
                <v-text-field
                    :value="item.Stock.size"
                    autocomplete="off"
                    class="custom force-text-left pa-1 bg-lightgrey"
                    clearable
                    dense
                    hide-details="auto"
                    v-if="item.type == 'othercharge'"
                    @change="updateLocalSalesInvoiceItemRowHandle( item.index, 'Stock.size',$event)"
                ></v-text-field>
                <div class="d-flex flex-row align-center" v-if="item.type == 'default'">
                    <div style="min-width: 50px !important;" class="font-16px">{{ $t('message.grade') + ":" }}</div>
                    <v-autocomplete
                        :items="allGrades"
                        :placeholder="$t('message.startTypingToSearch')"
                        :value="item.Stock.grade_id"
                        autocomplete="off"
                        class="custom pa-1 bg-lightgrey"
                        clearable
                        dense
                        hide-details="auto"
                        item-text="Grade.title"
                        item-value="Grade.id"
                        return-object
                        v-if="item.type == 'default'"
                        @focus="currentRowIndex = item.index"
                        @change="updateLocalSalesInvoiceItemRowHandle( item.index, 'Stock.grade_id',$event)"
                    >
                        <template v-slot:selection="{ item }">
                            <div class="text-wrap" style="width: 100%">{{ item.Grade.title }}</div>
                        </template>
                    </v-autocomplete>
                </div>
            </template>
            <template v-slot:item.Stock.thick="{ item }">
                <div class="d-flex flex-row align-center" v-if="item.type == 'default'">
                    <div style="min-width: 50px !important;" class="font-16px">{{ $t('message.thick') + ":" }}</div>
                    <v-text-field
                        :value="item.Stock.thick"
                        autocomplete="off"
                        class="custom pa-1 bg-lightgrey col-5"
                        clearable
                        dense
                        hide-details="auto"
                        type="text"
                        @change="updateLocalSalesInvoiceItemRowHandle( item.index, 'Stock.thick',$event)"
                    ></v-text-field>
                    <v-select
                        :items="uofmDimensions"
                        :value="item.Stock.smeasurement_id"
                        autocomplete="off"
                        class="custom pa-1 bg-lightgrey ml-1"
                        clearable
                        dense
                        hide-details="auto"
                        item-value="Measurement.id"
                        item-text="Measurement.title"
                        @change="updateLocalSalesInvoiceItemRowHandle( item.index, 'Stock.smeasurement_id',$event)"
                    ></v-select>
                </div>
                <div class="d-flex flex-row align-center" v-if="item.type == 'default'">
                    <div style="min-width: 50px !important;" class="font-16px">{{ $t('message.width') + ":" }}</div>
                    <v-text-field
                        :value="item.Stock.width"
                        autocomplete="off"
                        class="custom pa-1 bg-lightgrey col-5"
                        clearable
                        dense
                        hide-details="auto"
                        type="text"
                        @change="updateLocalSalesInvoiceItemRowHandle( item.index, 'Stock.width',$event)"
                    ></v-text-field>
                    <v-select
                        :items="uofmDimensions"
                        :value="item.Stock.wmeasurement_id"
                        autocomplete="off"
                        class="custom pa-1 bg-lightgrey ml-1"
                        clearable
                        dense
                        hide-details="auto"
                        item-value="Measurement.id"
                        item-text="Measurement.title"
                        @change="updateLocalSalesInvoiceItemRowHandle( item.index, 'Stock.wmeasurement_id',$event)"
                    ></v-select>
                </div>
                <div class="d-flex flex-row align-center" v-if="item.type == 'default'">
                    <div style="min-width: 50px !important;" class="font-16px">{{ $t('message.length') + ":" }}</div>
                    <v-text-field
                        :value="item.Stock.length"
                        autocomplete="off"
                        class="custom pa-1 bg-lightgrey col-5"
                        clearable
                        dense
                        hide-details="auto"
                        type="text"
                        @change="updateLocalSalesInvoiceItemRowHandle( item.index, 'Stock.length',$event)"
                    ></v-text-field>
                    <v-select
                        :items="uofmDimensions"
                        :value="item.Stock.lmeasurement_id"
                        autocomplete="off"
                        class="custom pa-1 bg-lightgrey ml-1"
                        clearable
                        dense
                        hide-details="auto"
                        item-value="Measurement.id"
                        item-text="Measurement.title"
                        @change="updateLocalSalesInvoiceItemRowHandle( item.index, 'Stock.lmeasurement_id',$event)"
                    ></v-select>
                </div>
            </template>
            <template v-slot:item.Stock.piece="{ item }">
                <div class="d-flex flex-row align-center" v-if="item.type == 'default'">
                    <div style="min-width: 50px !important;" class="font-16px">{{ $t('message.pcs') + ":" }}</div>
                    <v-text-field
                        :value="item.Stock.piece"
                        autocomplete="off"
                        class="custom pa-1 bg-lightgrey"
                        clearable
                        dense
                        hide-details="auto"
                        type="number"
                        v-if="item.type == 'default'"
                        @change="updateLocalSalesInvoiceItemRowHandle( item.index, 'Stock.piece',$event)"
                    ></v-text-field>
                </div>
                <div class="d-flex flex-row align-center" v-if="item.type == 'default'">
                    <div style="min-width: 50px !important;" class="font-16px">{{ $t('message.qty') + ":" }}</div>
                    <v-text-field
                        :value="item.Stock.volume"
                        autocomplete="off"
                        class="custom pa-1 bg-lightgrey"
                        clearable
                        dense
                        hide-details="auto"
                        type="number"
                        v-if="item.type == 'default'"
                        @change="updateLocalSalesInvoiceItemRowHandle( item.index, 'Stock.volume',$event)"
                    ></v-text-field>
                </div>
                <div class="d-flex flex-row align-center" v-if="item.type == 'default'">
                    <div style="min-width: 50px !important;" class="font-16px">{{ $t('message.unit') + ":" }}</div>
                    <v-select
                        :items="uofmQty"
                        :value="item.Stock.soldmeasurement_id"
                        autocomplete="off"
                        class="custom pa-1 bg-lightgrey"
                        clearable
                        dense
                        hide-details="auto"
                        item-value="Measurement.id"
                        item-text="Measurement.title"
                        v-if="item.type == 'default'"
                        @change="updateLocalSalesInvoiceItemRowHandle( item.index, 'Stock.soldmeasurement_id',$event)"
                    ></v-select>
                </div>
            </template>
            <template v-slot:item.Stock.costprice="{ item }">
                <div class="d-flex flex-row align-center" v-if="item.type == 'default'">
                    <div style="min-width: 50px !important;" class="font-16px">{{ $t('message.buying') + ":" }}</div>
                    <v-text-field
                        :value="item.Stock.costprice"
                        autocomplete="off"
                        class="custom pa-1 bg-lightgrey"
                        clearable
                        dense
                        hide-details="auto"
                        type="number"
                        @change="updateLocalSalesInvoiceItemRowHandle( item.index, 'Stock.costprice',$event)"
                    ></v-text-field>
                    <div class="ml-1 mt-2 font-16px">{{ currency(item.Stock.StockOrder.currency_id) }}</div>
                </div>
                <div class="d-flex flex-row align-center" v-if="item.type == 'default'">
                    <div style="min-width: 50px !important;" class="font-16px">{{ $t('message.fw') + ":" }}</div>
                    <v-text-field
                        :value="item.Stock.forwardingcharge"
                        autocomplete="off"
                        class="custom pa-1 bg-lightgrey"
                        clearable
                        dense
                        hide-details="auto"
                        type="number"
                        @change="updateLocalSalesInvoiceItemRowHandle( item.index, 'Stock.forwardingcharge',$event)"
                    ></v-text-field>
                    <div class="ml-1 mt-2 font-16px">{{ currency(item.Stock.StockOrder.currency_id) }}</div>
                </div>
                <div class="d-flex flex-row align-center" v-if="item.type == 'default'">
                    <div style="min-width: 50px !important;" class="font-16px">{{ $t('message.wh') + ":" }}</div>
                    <v-text-field
                        :value="item.Stock.warehousecost"
                        autocomplete="off"
                        class="custom pa-1 bg-lightgrey"
                        clearable
                        dense
                        hide-details="auto"
                        type="number"
                        @change="updateLocalSalesInvoiceItemRowHandle( item.index, 'Stock.warehousecost',$event)"
                    ></v-text-field>
                    <div class="ml-1 mt-2 font-16px">{{ currency(item.Stock.StockOrder.currency_id) }}</div>
                </div>
            </template>
            <template v-slot:item.Stock.sellingprice="{ item }">
                <div class="d-flex flex-row">
                    <v-text-field
                        :value="item.Stock.sellingprice"
                        autocomplete="off"
                        class="custom pa-1 bg-lightgrey"
                        clearable
                        dense
                        hide-details="auto"
                        type="number"
                        @change="updateLocalSalesInvoiceItemRowHandle( item.index, 'Stock.sellingprice',$event)"
                    ></v-text-field>
                    <span class="ml-1 mt-2 font-16px">{{ currency(LocalSalesInvoice__currency_id) }}</span>
                </div>
            </template>
            <template v-slot:item.Stock.amount="{ item }">
                <div class="mt-2 text-no-wrap text-right font-16px">{{ formatThisNumber(item.Stock.amount, findCurrencyPrecision(LocalSalesInvoice__currency_id)) + " " + currency(LocalSalesInvoice__currency_id) }}</div>
            </template>
            <template v-slot:item.Stock.id="{ item }">
                <div class="ml-2" v-if="item.type == 'othercharge'">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn icon @click="deleteItem(item.index, item.Stock.id)" v-on="on">
                                <v-icon color="grey">delete</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('message.deleteThisItem') }}</span>
                    </v-tooltip>
                </div>
                <div v-else class="mt-2 text-center font-weight-bold font-16px">{{ $t('message.na') }}</div>
            </template>
            <template v-slot:footer>
                <div class="pt-0 d-flex justify-space-between">
                    <div class="col-4 col-lg-4 col-md-3 pt-5 px-0 d-flex justify-start">
                        <v-btn
                            @click="addOtherChargesItemRow(statePrefix)"
                            color="success"
                            small
                        >{{ $t('message.addOtherCharges') }}
                        </v-btn>
                    </div>
                    <div class="dense-inputs col-8 col-lg-8 col-md-9 pa-0">
                        <v-row justify="end">
                            <v-col cols="1" lg="2" md="2" xs="3" class="pl-1 pr-3 pb-0">
                                <div class="text-right font-weight-black">{{ $t('message.totalPieces') }}</div>
                                <div class="text-right font-weight-black">{{ itemsTotalPieces }}</div>
                            </v-col>
                            <v-col cols="2" lg="2" md="2" xs="3" class="px-1 pb-0">
                                <div class="text-right font-weight-black">{{ $t('message.totalVolAndQty') }}</div>
                                <div class="text-right font-weight-black d-flex flex-column">
                                        <span v-for="(total,index) in itemsTotalQty" :class="itemsTotalQty.length > 1 && index == 0  ? 'pt-1' : ''">
                                            {{ formatThisNumber(total.Total.qty,uofmPrecision(total.Total.uofm)) + ' '+ total.Total.uofm }}
                                        </span>
                                </div>
                            </v-col>
                            <v-col cols="1" lg="2" md="2" xs="3" class="px-1 pb-0">
                                <div class="text-right font-weight-black">{{ $t('message.currency') }}</div>
                                <v-container class="ma-0 pa-0">
                                    <v-row no-gutters>
                                        <v-col cols="9" offset="3">
                                            <v-select
                                                :items="allCurrencies"
                                                class="custom py-0 mt-0 bg-lightgrey"
                                                hide-details
                                                item-text="Currency.code"
                                                item-value="Currency.id"
                                                reverse
                                                v-model="LocalSalesInvoice__currency_id"
                                            >
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-col>
                            <v-col cols="1" lg="2" md="2" xs="3" class="px-1 pb-0">
                                <div class="text-right font-weight-black">
                                    <span v-if="LocalSalesInvoice__currency_id == 2">{{ allCurrencies.find(c => c.Currency.id == Office__currency_id).Currency.code + ':' + allCurrencies.find( c => c.Currency.id == 2).Currency.code + ' ' + $t('message.exRate') }}</span>
                                    <span v-else>{{ allCurrencies.find(c => c.Currency.id == LocalSalesInvoice__currency_id).Currency.code + ':' + allCurrencies.find( c => c.Currency.id == 2).Currency.code + ' ' + $t('message.exRate') }}</span>
                                </div>
                                <v-container class="ma-0 pa-0">
                                    <v-text-field
                                        :value="LocalSalesInvoice__gstrmrate"
                                        class="custom py-1 pb-2 mt-0 bg-lightgrey"
                                        dense
                                        hide-details
                                        type="number"
                                        @change="LocalSalesInvoice__gstrmrate = $event"
                                    >
                                    </v-text-field>
                                </v-container>
                            </v-col>
                            <v-col cols="1" lg="2" md="2" xs="3" class="pl-1 pr-3 pb-0">
                                <div class="text-right font-weight-black">{{ $t('message.totalAmount') + '(' + allCurrencies.find(c => c.Currency.id == 2).Currency.code +')' }}</div>
                                <div class="text-right font-weight-black">
                                    <span v-if="LocalSalesInvoice__currency_id == 2">{{ formatThisNumber((itemsTotalAmount),currencyFormatPrecision) }}</span>
                                    <span v-else>{{ formatThisNumber((itemsTotalAmount / LocalSalesInvoice__gstrmrate),currencyFormatPrecision) }}</span>
                                </div>
                            </v-col>
                            <v-col cols="1" lg="2" md="2" xs="3" class="pl-1 pr-3 pb-0">
                                <div class="text-right font-weight-black">
                                    <span v-if="LocalSalesInvoice__currency_id == 2">{{ $t('message.totalAmount') + '(' + allCurrencies.find(c => c.Currency.id == Office__currency_id).Currency.code +')' }}</span>
                                    <span v-else>{{ $t('message.totalAmount') + '(' + allCurrencies.find(c => c.Currency.id == LocalSalesInvoice__currency_id).Currency.code +')' }}</span>
                                </div>
                                <div class="text-right font-weight-black">
                                    <span v-if="LocalSalesInvoice__currency_id == 2">{{ formatThisNumber(itemsTotalAmount * LocalSalesInvoice__gstrmrate,currencyFormatPrecision) }}</span>
                                    <span v-else>{{ formatThisNumber(itemsTotalAmount,currencyFormatPrecision) }}</span>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </template>
        </v-data-table>
        </v-lazy>
    </div>
</template>

<script>
    import {mapFields} from "vuex-map-fields";
    import {mapFieldsPrefixed, mapMultiRowFieldsPrefixed} from "Helpers/vuex-map-fields-prefixed";
    import {mapActions, mapGetters, mapMutations} from "vuex";
    import {formatDate, numberFormat} from "Helpers/helpers";
    import LoadingItems from "Components/Appic/LoadingItems";

    export default {
        name: "LocalSalesInvoiceItemListNew",
        components: {LoadingItems},
        props: ['updateMode'],
        data () {
            return {
                certifications: [
                    {text: 'FSCCW', value: 'FSCCW'},
                    {text: 'FSCCOC', value: 'FSCCOC'},
                    {text: 'PEFC', value: 'PEFC'}
                ],
                currentRowIndex: 0,
                dialogs: {
                    confirm:  false,
                    confirm_message: ""
                },
                disablePagination: true,
                itemsPerPage: -1,
                loading: {
                    addOtherCharge: false,
                },
                itemsLoaded: false
            }
        },
        computed: {
            statePrefix() {
                if (this.updateMode) {
                    return 'update'
                } else {
                    return 'current'
                }
            },
            ...mapFieldsPrefixed('localsalesinvoice', {
                LocalSalesInvoice__currency_id: 'LocalSalesInvoice.currency_id',
                LocalSalesInvoice__gstpercent: 'LocalSalesInvoice.gstpercent',
                LocalSalesInvoice__gstrmrate: 'LocalSalesInvoice.gstrmrate',
                LocalSalesInvoice__id: 'LocalSalesInvoice.id',
                LocalSalesInvoice__office_id: 'LocalSalesInvoice.office_id',
                LocalSalesInvoice__salestatus_id: 'LocalSalesInvoice.salestatus_id',
            },'statePrefix'),
            ...mapFields('localsalesinvoice',{
                newLocalSalesInvoiceSaved: 'newLocalSalesInvoiceSaved',
                newLocalSalesInvoiceUnsaved: 'newLocalSalesInvoiceUnsaved',
                updatedLocalSalesInvoiceUnsaved: 'updatedLocalSalesInvoiceUnsaved',
                updatedLocalSalesInvoiceSaved: 'updatedLocalSalesInvoiceSaved'
            }),
            ...mapMultiRowFieldsPrefixed('localsalesitem',{
                LocalSalesInvoiceItems: 'LocalSalesInvoiceItems',
            },'statePrefix'),
            ...mapFieldsPrefixed('localsalesitem',{
                gstpercent: 'gstpercent',
                itemsCurrencyRate: 'itemsCurrencyRate',
                itemsTotalAmount: 'itemsTotalAmount',
                itemsTotalPieces: 'itemsTotalPieces',
                itemsTotalQty: 'itemsTotalQty'
            },'statePrefix'),
            ...mapGetters('certification',{
                allCertifications: 'allCertifications'
            }),
            ...mapGetters('currency',{
                allCurrencies: 'allCurrencies'
            }),
            ...mapGetters('grade',{
                allGrades: 'allGrades',
                findGradeById: 'findGradeById'
            }),
            ...mapGetters('productgroup',{
                allProductGroups: 'allProductGroups'
            }),
            ...mapGetters('species', {
                allSpecies: 'allSpecies',
                findSpeciesById: 'findSpeciesById'
            }),
            ...mapGetters([
                // 'certificationsNew',
                'currencies',
                'offices',
                'productGroups',
                'uofmDimensions',
                'uofmQty'
            ]),
            currencyFormatPrecision () {
                return this.allCurrencies.find((currency) => currency.Currency.id === this.LocalSalesInvoice__currency_id)?.Currency?.formatPrecision
            },
            headers() {
                return [
                    {
                        id: 0,
                        text: this.$t('message.lineNo'),
                        value: 'lineNumber'
                    },
                    {
                        id: 1,
                        text: this.$t('message.bundle'),
                        value: 'Stock.bundleno'
                    },
                    {
                        id: 5,
                        text: this.$t('message.certification'),
                        value: 'Stock.fsc'
                    },
                    {
                        id: 2,
                        text: this.$t('message.description'),
                        value: 'Stock.salesdescription_id'
                    },
                    {
                        id: 7,
                        text: this.$t('message.size'),
                        value: 'Stock.thick'
                    },
                    {
                        id: 10,
                        text: this.$t('message.quantity'),
                        value: 'Stock.piece'
                    },
                    {
                        id: 13,
                        text: this.$t('message.costPrice'),
                        value: 'Stock.costprice'
                    },
                    {
                        id: 16,
                        text: this.$t('message.salesPrice'),
                        value: 'Stock.sellingprice'
                    },
                    {
                        id: 17,
                        text: this.$t('message.amount'),
                        value: 'Stock.amount'
                    },
                    {
                        id: 18,
                        text: this.$t('message.actions'),
                        value: 'Stock.id'
                    }
                ]
            },
            Office__currency_id () {
                let office = this.offices.find(o => o.Office.id == this.LocalSalesInvoice__office_id)
                return office.Office.currency_id
            },
        },
        methods: {
            ...mapActions('certification',{
                getAllCertifications: 'getAllCertifications'
            }),
            ...mapActions('currency',{
                getAllCurrencies: 'getAllCurrencies'
            }),
            ...mapActions('localsalesitem',{
                addOtherChargesItemRow: 'addOtherChargesItemRow',
                deleteLocalSalesInvoiceItem: 'deleteLocalSalesInvoiceItem',
                updateTotals: 'updateTotals'
            }),
            ...mapActions('grade', {
                getAllGrades: 'getAllGrades'
            }),
            ...mapActions('productgroup', {
                getAllProductGroups: 'getAllProductGroups'
            }),
            ...mapActions('species', {
                getAllSpecies: 'getAllSpecies'
            }),
            ...mapMutations('localsalesitem',{
                deleteLocalSalesInvoiceItemRow: 'deleteLocalSalesInvoiceItemRow',
            }),
            currency(currency_id) {
                const currency = this.allCurrencies.find((currency) => currency.Currency.id == currency_id)
                if(currency){
                    return currency.Currency.code
                }
                return ''
            },
            async deleteItem (itemIndex, itemId) {
                if (await this.$root.$confirm(this.$t('message.delete'), this.$t('message.confirmations.continueDeleteItemAction'), {color: 'red'})) {
                    let promise = new Promise((resolve, reject) => {
                        let payload = {
                            index: itemIndex,
                            prefix: this.statePrefix
                        }
                        this.deleteLocalSalesInvoiceItemRow(payload)
                        resolve('ok')
                    });
                    promise.then(() => {
                        if(itemId != null){
                            this.deleteLocalSalesInvoiceItem({
                                localSalesInvoiceId: this.LocalSalesInvoice__id,
                                itemId: itemId}
                            )
                                .then((response)=>{
                                    this.updateTotals( this.statePrefix )
                                })
                        } else {
                            this.updateTotals( this.statePrefix )
                        }
                    })
                }
            },
            findCurrencyPrecision (val) {
                let currency = this.allCurrencies.find(c => c.Currency.id == val)
                return currency?.Currency?.formatPrecision
            },
            formatDate,
            formatThisNumber (value,format) {
                return numberFormat(value,format)
            },
            uofmPrecision (uofm) {
                if(uofm) {
                    if(isNaN(uofm)) {
                        return this.uofmQty.find((u) => u.Measurement.title == uofm.trim())?.Measurement?.formatPrecision
                    } else {
                        return this.uofmQty.find((u) => u.Measurement.id == uofm)?.Measurement?.formatPrecision
                    }
                } else {
                    return '0,0.00'
                }
            },
            updateLocalSalesInvoiceItemRowHandle (idx,field,value) {
                switch(field) {
                    case 'Stock.soldmeasurement_id':
                        var payload = {
                            index: idx,
                            field: field,
                            prefix: this.statePrefix,
                            value: value,
                        }
                        //assign Soldmeasurement property in Stock
                        let measurement = this.uofmQty.filter(uofm => uofm.Measurement.id === value)[0];
                        if(measurement != null) {
                            this.$store.commit('localsalesitem/updateLocalSalesInvoiceItems', {
                                index: idx,
                                field: 'Soldmeasurement.id',
                                prefix: this.statePrefix,
                                value: measurement.Measurement.id != null ? measurement.Measurement.id : null
                            })
                            this.$store.commit('localsalesitem/updateLocalSalesInvoiceItems', {
                                index: idx,
                                field: 'Soldmeasurement.title',
                                prefix: this.statePrefix,
                                value: measurement.Measurement.title != null ? measurement.Measurement.title : null
                            })
                        }
                        break;
                    case 'Stock.species_id':
                        var payload = {
                            index: idx,
                            field: field,
                            prefix: this.statePrefix,
                            value: value?.Species?.id
                        }
                        // assign Species property in Stock
                        this.$store.commit('localsalesitem/updateLocalSalesInvoiceItems', {
                            index: idx,
                            field: 'Species.id',
                            prefix: this.statePrefix,
                            value: value?.Species?.id
                        })
                        this.$store.commit('localsalesitem/updateLocalSalesInvoiceItems', {
                            index: idx,
                            field: 'Species.title',
                            prefix: this.statePrefix,
                            value: value?.Species?.title
                        })
                        break
                    case 'Stock.grade_id':
                        var payload = {
                            index: idx,
                            field: field,
                            prefix: this.statePrefix,
                            value: value?.Grade?.id
                        }
                        // assign Grade property in Stock
                        this.$store.commit('localsalesitem/updateLocalSalesInvoiceItems', {
                            index: idx,
                            field: 'Grade.id',
                            prefix: this.statePrefix,
                            value: value?.Grade?.id
                        })
                        this.$store.commit('localsalesitem/updateLocalSalesInvoiceItems', {
                            index: idx,
                            field: 'Grade.title',
                            prefix: this.statePrefix,
                            value: value?.Grade?.title
                        })
                        break
                    default:
                        var payload = {
                            index: idx,
                            field: field,
                            prefix: this.statePrefix,
                            value: value,
                        }
                }
                let promise = new Promise((resolve, reject) => {
                    this.$store.commit('localsalesitem/updateLocalSalesInvoiceItems', payload)
                    resolve('ok')
                });
                promise.then(()=>{
                    this.updateTotals(this.statePrefix)
                })
            }
        },
        watch: {},
        created () {
            if(this.LocalSalesInvoiceItems.length > 20){
                this.disablePagination = false
                this.itemsPerPage = 10
            }
            if(this.allCurrencies.length == 0) this.getAllCurrencies()
            if(this.allSpecies.length == 0) this.getAllSpecies()
            if(this.allGrades.length == 0) this.getAllGrades()
            if(this.allCertifications.length == 0) this.getAllCertifications()
            if(this.allProductGroups.length == 0) this.getAllProductGroups()

            this.itemsCurrencyRate = this.LocalSalesInvoice__gstrmrate
            this.gstpercent = this.LocalSalesInvoice__gstpercent
        },
        mounted() {
            this.$store.watch(
                function (state) {
                    return state.appic.localsalesitem.update.LocalSalesInvoiceItems
                },
                () => {
                    this.updatedLocalSalesInvoiceUnsaved = true
                },
                {deep: true}
            )
            this.$store.watch(
                function (state) {
                    return state.appic.localsalesitem.current.LocalSalesInvoiceItems
                },
                () => {
                    this.newLocalSalesInvoiceUnsaved = true
                },
                {deep: true}
            )
            this.itemsLoaded = true
        },
        updated() {
            this.itemsLoaded = true
        }
    }
</script>

<style>
.bg-lightgrey {
    background-color: #F5F5F5;
}
.v-text-field__slot input {
    text-align: right;
}
.custom.v-text-field {
    font-size: 0.75rem !important;
}
.custom.v-text-field>.v-input__control>.v-input__slot:before {
    border-style: none;
}
.custom.v-text-field>.v-input__control>.v-input__slot:after {
    border-style: none;
}
.v-data-table td {
    padding: 0 5px;
}
.font-xs {
    font-size: 0.65rem;
}
.custom .v-icon {
    font-size: 18px !important;
}
.truncate {
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.v-data-table tbody td {
    vertical-align: top !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    height: 40px !important;
    font-size: 0.75rem !important;
}
.row-reverse { flex-direction: row-reverse; }
.force-text-left {
    text-align: left !important;
}
.width-18-pct {
    width: 18%;
}
.font-16px {
    font-size: 16px !important;
    line-height: 20px;
}
.dense-inputs .v-input {
    font-size: 16px !important;
}
</style>